.dashboardnav{
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 4!important;
}

.admin_inqueries{
    margin-left: 12.8%!important;
}

/* Mobile */
@media (min-width:0px) and (max-width: 450px) {
    .fund-orderhistory-content {
        padding-left: 10px!important;
        padding-right: 10px!important;
    }
    .admin_messages{
        padding-left: 0px!important;
        padding-right: 0px!important;
        margin-left: 0px!important;
        margin-right: 0px!important;
    }
}

@media (min-width:451px) and (max-width: 575px) {
    .fund-orderhistory-content {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
    
        .admin_messages {
            padding-left: 0px !important;
            padding-right: 0px !important;
            margin-left: 0px !important;
            margin-right: 0px !important;
        }
}