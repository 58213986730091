.admin_dashcard {
  text-align: center;
  width: 405px;
  height: 230px;
  padding: 10px;
  margin-bottom: 25px;
  align-items: center;
  padding-top: 50px;
  text-align: center;
  border-radius: 30px;
  background: #FFF;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.08);
  float: left;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.admin_dashcard:hover {
  background: rgba(196, 224, 242, 0.60);
    /* Penumber */
    box-shadow: 0px 6px 30px 5px rgba(196, 224, 242, 0.12);
}

.admin_dashcard p {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 800;
}

.admin_dashcard img{
  height: 46px;
  width: 46px;
}

/* You can adjust the button styles as needed. */
.empty {
  text-align: center;
  width: 400px;
  height: 220px;
  padding: 10px 10px;
  margin-bottom: 30px;
  margin-right: 30px;
  align-items: center;
  padding-top: 70px;
  text-align: center;
  border-radius: 30px;
  float: left;
}

.ton{
  border: none;
}

.ton-tin{
  font-weight: normal;
  font-size: 15px;
}

.admin_dashboard_paragraph{
  font-size: 18px!important;
  font-weight: normal!important;
}

/* Mobile */
@media (min-width: 0px) and (max-width: 480px) {
  .admin_dashcard{
    width: 90%!important;
    height: auto!important;
  }
    .empty{
      display: none!important;
    }
}

/* Small devices (portrait) */
@media (min-width: 481px) and (max-width: 575px){
  .admin_dashcard {
      width: 95% !important;
      height: auto !important;
    }
        .empty {
          display: none !important;
        }
}