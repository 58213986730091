.cardsignupone {
    width: 56%;
    margin-top: -10%;
    padding: 10px 30px;
    flex-shrink: 0;
    border-radius: 30px;
    background: var(--Background-onecolor, #ECEFF1);
    margin-left: 21%;
    margin-right: 22%;
    text-align: center;
    margin-bottom: 5%;
}

.cardmainsip {
    color: rgba(252, 1, 31, 0.60);
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.signcardcontent {
    text-align: start;
    margin-left: 10%;
}

.signcardcontent p {
    color: var(--Navbar-color, #616161);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.signcardcontent span {
    color: #FF0B0B;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.signcardiinput {
    width: 620px;
    height: 36px;
    padding-left: 15px;
    flex-shrink: 0;
    border-radius: 15px;
    border: 0.6px solid rgba(0, 0, 0, 0.60);
    background: var(--Background-onecolor, #fff);
}

.signcardsub {
    text-align: center;
    margin-bottom: 5%;
}

.signcardcontentir {
    margin-left: 9%;
}

.sign_up_btn{
    border: none;
}

/* Signupcard.css */

/* Style for the dialog container */
.sign_up_dialog {
    display: block;
    /* Initially hidden */
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

/* Style for the dialog content */
.sign_up_dialog-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    position: relative;
}

/* Style for the close button */
.sign_up_close {
    color: #aaa;
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

/* Style for the loading message */
.sign_up_dialog-content p {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
}

/* Add more styling as needed */

/* Mobile */
@media (max-width: 575px) {}