.dialog-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 13!important;
    background: rgba(0, 0, 0, 0.5);
    /* Darkish background */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Styling for the dialog box */
.dialog-box {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.close-button {
    position: absolute;
    top: 1vh;
    right: 3px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: rgb(237, 231, 231);
}

/* Mobile */
@media (min-width:0px) and (max-width: 450px) {
    .mainfundcontent {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (min-width:451px) and (max-width: 575px) {
    .mainfundcontent {
        padding-left: 10px;
        padding-right: 10px;
    }
}