.adminfooter {
    height: 65px;
    background-color: #000;
    display: flex;
    justify-content: space-between;
    color: white;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
    position: relative;
    overflow: hidden;
}

.adminfooter p {
    position: relative;
    z-index: 1;
    color: white;
}

.adminfooter a {
    position: relative;
    z-index: 1;
}

.adminfooter img {
    padding-left: 18px;
    height: 28px;
    width: 28px;
}

/* Mobile */
@media (min-width: 0px) and (max-width: 450px) {
    .adminfooter p{
        font-size: 14px!important;
    }

        .adminfooter img {
            padding-left: 18px;
            height: 20px!important;
            width: 20px!important;
        }
}

@media (min-width: 451px) and (max-width: 575px) {
    .adminfooter p {
        font-size: 15px !important;
    }

    .adminfooter img {
        padding-left: 18px;
        height: 25px !important;
        width: 25px !important;
    }
}