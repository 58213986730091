.adminregister_card {
    padding-left: 75px;
    padding-right: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dashboardnav{
    position: sticky;
    top: 0;
    width: 100%;
}

.admindashboard{
    background: #F5F5F5;
}

.adminregister_card_title p{
    padding-left: 75px;
}

/* Mobile */
@media (min-width:0px) and (max-width: 450px) {
    .adminregister_card {
        padding-left: 10px;
        padding-right: 10px;
        display: block!important;
    }
}

@media (min-width:451px) and (max-width: 575px) {
    .adminregister_card {
        padding-left: 10px;
        padding-right: 10px;
        display: block !important;
    }
}