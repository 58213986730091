.mcontactforms{
    /* background: #F5F5F5; */
    /* padding-top: 20px; */
    padding-bottom: 80px;
    /* padding-left: 60px;
    padding-right: 60px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.admin_main_contact_form_update{
    /* padding-left: 60px; */
    font-size: 24px;
    padding-top: 25px!important;
    padding-bottom: 15px!important;
    font-weight: 800;
}
.admin_contact_form{
    width: 601px;
    height: 444px;
    flex-shrink: 0;
    border-radius: 15.213px;
    background: #ECEFF1;
    box-shadow: 0px 5.77999px 28.89997px 4.81666px rgba(0, 0, 0, 0.12);
    padding: 30px;
}
.mtpfi{
    font-size: 30px;
    font-weight: 800;
    text-align: center;
}

.admin_update_input_field{
    padding: 1px 0px;
    padding-bottom: 10px;
}

.admin_update_input_field input{
    display: inline-flex;
    padding: 10.45px 114.64px 10.607px 19.266px;
    align-items: center;
    border-radius: 13.267px;
    width: 75.9%;
    margin-left: 3px;
    border: 0.963px solid rgba(97, 97, 97, 0.60);
}

.contactinputselect{
    display: inline-flex;
    padding: 9.45px 70.64px 9.607px 19.266px;
    align-items: center;
    border-radius: 13.267px;
    margin-left: 30px;
    border: 0.963px solid rgba(97, 97, 97, 0.60);
}

.contacttextareainput{
    display: flex;
    width: 573.006px;
    height: 125.233px;
    padding: 14.45px 0px 86.783px 19.266px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 19.267px;
    border: 0.963px solid rgba(97, 97, 97, 0.60);
}

.contactcountryo{
    padding-top: 1px;
    padding-bottom: 20px;
}

.admin_contact_textarea{
    margin-left: 1px;
    padding-top: 10px;
}

.contactpar{
    margin-left: 30px;
    padding-bottom: 40px;
    padding-top: 20px;
}

.admin_contact_button{
    text-align: center;
    padding-top: 35px;
}

.admin_contact_form_paragraph{
    margin-left: 1px;
    font-weight: 600;
}

.contactsrpasno{
    font-weight: 800;
}

.contactsrpasnoi{
    background: var(--Button-Hover-color, linear-gradient(96deg, rgba(36, 20, 19, 0.77) -10.44%, rgba(31, 106, 88, 0.77) 108.53%, rgba(1, 255, 209, 0.77) 108.54%, rgba(3, 255, 195, 0.77) 108.87%, rgba(11, 255, 196, 0.77) 108.87%, rgba(13, 253, 195, 0.77) 108.87%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* font-size: 19.267px; */
    font-style: normal;
    font-weight: 400;
    /* line-height: 23.12px; */
}

/* Mobile */


@media (min-width:0px) and (max-width: 380px) {
    .adminregister_card {
        padding-left: 10px;
        padding-right: 10px;
    }

    .admin_contact_form {
        width: 86% !important;
        height: auto !important;
    }

    .admin_update_input_field input {
        display: inline-flex;
        padding: 10.45px 0px 10.607px 19.266px;
        align-items: center;
        border-radius: 13.267px;
        width: 85.9% !important;
    }
}

@media (min-width:381px) and (max-width: 400px) {
    .adminregister_card {
        padding-left: 10px;
        padding-right: 10px;
    }

    .admin_contact_form {
        width: 92% !important;
        height: auto !important;
    }

    .admin_update_input_field input {
        display: inline-flex;
        padding: 10.45px 0px 10.607px 19.266px;
        align-items: center;
        border-radius: 13.267px;
        width: 85.9% !important;
    }
}

@media (min-width:401px) and (max-width: 450px) {
    .adminregister_card {
        padding-left: 10px;
        padding-right: 10px;
    }
 .admin_contact_form {
     width: 100% !important;
     height: auto !important;
 }
 .admin_update_input_field input {
     display: inline-flex;
     padding: 10.45px 0px 10.607px 19.266px;
     align-items: center;
     border-radius: 13.267px;
     width: 85.9% !important;
 }
}
@media (min-width:451px) and (max-width: 575px) {
    .adminregister_card {
        padding-left: 10px;
        padding-right: 10px;
    }

    .admin_contact_form {
        width: 63vh !important;
        height: auto !important;
    }

    .admin_update_input_field input {
        display: inline-flex;
        padding: 10.45px 0px 10.607px 19.266px;
        align-items: center;
        border-radius: 13.267px;
        width: 85.9% !important;
    }
}