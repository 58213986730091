.mainaddnav{
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 12;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}
.mainaddfunds{
    background: #F5F5F5;
}

.addnewplan{
    font-size: 24px;
    font-weight: 600;
    padding-top: 30px;
    padding-bottom: 10px;
}

.addnewplan span{
    background: var(--Button-Hover-color, linear-gradient(96deg, rgba(36, 20, 19, 0.77) -10.44%, rgba(31, 106, 88, 0.77) 108.53%, rgba(1, 255, 209, 0.77) 108.54%, rgba(3, 255, 195, 0.77) 108.87%, rgba(11, 255, 196, 0.77) 108.87%, rgba(13, 253, 195, 0.77) 108.87%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.addpagecontent{
    padding-left: 75px;
    padding-right: 75px;
    padding-bottom: 60px;
}

.addplancards{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.addmainupdates{
    width: 74%;
    margin-left: 13%;
    margin-right: 13%;
    margin-top: 40px;
    height: auto;
    padding-bottom: 60px;
}

/* Mobile */
@media (min-width:0px) and (max-width: 450px) {
    .addpagecontent {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (min-width:451px) and (max-width: 575px) {
    .addpagecontent {
        padding-left: 10px;
        padding-right: 10px;
    }
}