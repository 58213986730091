.mmprice{
  background: var(--Background-onecolor, #ECEFF1);
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 32px;
  padding-right: 32px;
  text-align: center;
}

.mmpri{
  color: #000;
  font-family: Nunito;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 33.531px; 
}

.mmpric{
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; 
}

/* Mobile */
@media (min-width:401px) and (max-width: 480px){
  .mmprice {
      height: 142vh;
  }
    .mmpri {
      padding-top: 135px;
      font-size: 0px;
    }
  
    .mmpric {
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 0px;
    }
  
    .mmpri-pricing-main-price {
      padding-top: 70px;
    }
}

@media (min-width:381px) and (max-width: 400px) {
  .mmprice {
    height: 150vh;
  }

  .mmpri {
    padding-top: 135px;
    font-size: 0px;
  }

  .mmpric {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 0px;
  }

  .mmpri-pricing-main-price {
    padding-top: 70px;
  }
}

@media (min-width:300px) and (max-width: 380px) {
  .mmprice {
    height: 190vh;
  }
    .mmpri {
      padding-top: 135px;
      font-size: 0px;
    }
  
    .mmpric {
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 0px;
    }
  
    .mmpri-pricing-main-price {
      padding-top: 70px;
    }
}

@media (min-width:481px) and (max-width: 575px) {
  .mmprice{
    height: 169vh;
  }
  .mmpri{
    padding-top: 135px;
    font-size: 0px;
  }

  .mmpric{
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 0px;
  }
  .mmpri-pricing-main-price{
    padding-top: 70px;
  }
}