.Sselectoptions{
    width: 638.5px;
    height: 38.9px;
    padding-left: 15px;
    flex-shrink: 0;
    border-radius: 15px;
    border: 0.54px solid rgba(0, 0, 0, 0.60);
    background: var(--Background-onecolor, #fff);
}

@media (max-width: 575px){
    
}