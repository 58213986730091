.virtual_support_navbar{
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 2;
}

/* Mobile */
@media (min-width:0px) and (max-width: 450px) {
    .mynewplan-orderhistory-content {
        padding-left: 10px!important;
        padding-right: 10px!important;
    }

    .support-messages-main{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

@media (min-width:451px) and (max-width: 575px) {
    .mynewplan-orderhistory-content {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .support-messages-main {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}