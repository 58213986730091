.virtual-dashboard{
    background: #F5F5F5;
}

.virtual_dashboard_navbar{
    position: sticky;
    top: 0;
    width: 100%;
}

.virtual_dashboard_main{
    padding-left: 75px;
    padding-right: 75px;
    min-height: 100vh;
}

.virtual_dashboard_title p{
    font-size: 24px;
    font-weight: 600;
    padding-top: 30px;
    padding-bottom: 10px;
}

.virtual_dashboard_title_span {
    background: var(--Button-Hover-color, linear-gradient(96deg, rgba(36, 20, 19, 0.77) -10.44%, rgba(31, 106, 88, 0.77) 108.53%, rgba(1, 255, 209, 0.77) 108.54%, rgba(3, 255, 195, 0.77) 108.87%, rgba(11, 255, 196, 0.77) 108.87%, rgba(13, 253, 195, 0.77) 108.87%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.virtual_dashboard_title h1 {
    padding-top: 30px;
    padding-bottom: 5px;
    margin-left: 5px;
}

/* Mobile */
@media (max-width: 575px) {
    .virtual_dashboard_main {
            padding-left: 10px;
            padding-right: 10px;
    }

    .addmainupdates{
        display: none;
    }

    .virtual_dashboard_title p{
        padding-top: 0px;
    }
}