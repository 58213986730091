.S_selectoptions{
    display: inline-flex;
    padding: 10.45px 344.64px 10.607px 19.266px;
    align-items: center;
    border-radius: 13.267px;
    margin-left: 3px;
    background-color: white;
    border: 0.963px solid rgba(97, 97, 97, 0.60);
    margin-bottom: 10px;
    margin-top: 5px;
    color: rgb(0, 0, 0, 0.5);
}

/* Mobile */
@media (min-width: 30px) and (max-width: 575px){
.S_selectoptions {
        margin: 10px;
            width: 85% !important;
            margin-left: 0px !important;
            display: inline-flex;
            padding: 10.45px 0px 10.607px 19.266px;
            align-items: center;
            border-radius: 13.267px;
            margin-left: 3px;
            border: 0.963px solid rgba(97, 97, 97, 0.60);
    }
}