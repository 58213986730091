.faqhead{
  padding-left: 32px;
  padding-right: 32px;
}

.faqhead img{
  height: 35px;
  width: 35px;
}

.faqhead h1{
  font-family: Nunito;
  font-size: 24px;
  font-weight: 600;
  padding-left: 32px; 
  margin-bottom: 2%;
  margin-top: 2%;
}

.faq_navbar{
  position: sticky;
  top: 0;
  width: 100%;
}

.faqhead1 h1{
  font-family: Nunito;
  font-size: 24px;
  font-weight: 600;
  padding-left: 32px; 
  margin-bottom: 2%;
  margin-top: 3%;
}

.faqhead1{
  margin-bottom: 5%;
  cursor: pointer;
}

.faqhead p{
  margin-left: 40px;
  border-radius: 15px;
  display: flex;
  width: 1228px;
  padding: 31px 102px 25px 25px;
  align-items: center;
  gap: 39px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.faqp{
  color: var(--Navbar-color, #616161);
  font-family: Nunito;
  font-weight: 600;
  line-height: 27.927px; 
  }

  .hidediv{
    font-family: Nunito;
    font-weight: 400;
    padding-left: 65px;
    padding-bottom: 10px;
    color: var(--Navbar-color, #616161);
  }

/* Mobile */
@media (min-width:0px) and (max-width: 450px) {
.faqhead {
    padding-left: 5px!important;
    padding-right: 5px!important;
    min-height: 100vh!important;
  }

  .faqhead img {
    height: 25px !important;
    width: 25px !important;
  }

  .faqhead h1 {
    font-family: Nunito;
    font-size: 18px!important;
    font-weight: 600;
    padding-left: 10px !important;
    margin-bottom: 2% !important;
    margin-top: 2%;
  }

  .faq_navbar {
    position: sticky;
    top: 0;
    width: 100%;
  }

  .faqhead1 h1 {
    font-family: Nunito;
    font-size: 18px!important;
    font-weight: 600;
    padding-left: 10px !important;
    margin-bottom: 2%;
    margin-top: 3%;
  }

  .faqhead1 {
    margin-bottom: 5%;
    cursor: pointer;
  }

  .faqhead p {
    margin-left: 10px!important;
    border-radius: 15px;
    display: flex;
    width: 86% !important;
    padding: 10px 10px 10px 10px !important;
    align-items: center;
    gap: 30px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }

  .faqp {
    color: var(--Navbar-color, #616161);
    font-family: Nunito;
    font-weight: 600;
    line-height: 27.927px;
  }

  .hidediv {
    font-family: Nunito;
    font-weight: 400;
    padding-left: 10px;
    padding-bottom: 10px;
    color: var(--Navbar-color, #616161);
  }
}

@media (min-width:401px) and (max-width: 500px) {
.faqhead {
    padding-left: 5px !important;
    padding-right: 5px !important;
    min-height: 100vh!important;
  }

  .faqhead img {
    height: 25px !important;
    width: 25px !important;
  }

  .faqhead h1 {
    font-family: Nunito;
    font-size: 18px!important;
    font-weight: 600;
    padding-left: 10px !important;
    margin-bottom: 2% !important;
    margin-top: 2%;
  }

  .faq_navbar {
    position: sticky;
    top: 0;
    width: 100%;
  }

  .faqhead1 h1 {
    font-family: Nunito;
    font-size: 18px!important;
    font-weight: 600;
    padding-left: 10px !important;
    margin-bottom: 2%;
    margin-top: 3%;
  }

  .faqhead1 {
    margin-bottom: 5%;
    cursor: pointer;
  }

  .faqhead p {
    margin-left: 10px !important;
    border-radius: 15px;
    display: flex;
    width: 89% !important;
    padding: 10px 10px 10px 10px !important;
    align-items: center;
    gap: 30px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }

  .faqp {
    color: var(--Navbar-color, #616161);
    font-family: Nunito;
    font-weight: 600;
    line-height: 27.927px;
  }

  .hidediv {
    font-family: Nunito;
    font-weight: 400;
    padding-left: 10px;
    padding-bottom: 10px;
    color: var(--Navbar-color, #616161);
  }
}

@media (min-width:501px) and (max-width: 575px) {
  .faqhead {
    padding-left: 5px !important;
    padding-right: 5px !important;
    min-height: 100vh !important;
  }

  .faqhead img {
    height: 25px !important;
    width: 25px !important;
  }

  .faqhead h1 {
    font-family: Nunito;
    font-size: 18px !important;
    font-weight: 600;
    padding-left: 10px !important;
    margin-bottom: 2% !important;
    margin-top: 2%;
  }

  .faq_navbar {
    position: sticky;
    top: 0;
    width: 100%;
  }

  .faqhead1 h1 {
    font-family: Nunito;
    font-size: 18px !important;
    font-weight: 600;
    padding-left: 10px !important;
    margin-bottom: 2%;
    margin-top: 3%;
  }

  .faqhead1 {
    margin-bottom: 5%;
    cursor: pointer;
  }

  .faqhead p {
    margin-left: 10px !important;
    border-radius: 15px;
    display: flex;
    width: 92% !important;
    padding: 10px 10px 10px 10px !important;
    align-items: center;
    gap: 30px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }

  .faqp {
    color: var(--Navbar-color, #616161);
    font-family: Nunito;
    font-weight: 600;
    line-height: 27.927px;
  }

  .hidediv {
    font-family: Nunito;
    font-weight: 400;
    padding-left: 10px;
    padding-bottom: 10px;
    color: var(--Navbar-color, #616161);
  }
}