.addupdates{
    height: auto;
    /* padding-top: 10px; */
    padding-bottom: 60px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 30px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}

.addupdates h1{
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    color: rgba(140, 39, 39, 0.842);
    padding-top: 20px;
    padding-bottom: 20px;
}

.cardupdates{
    width: 93%;
    margin-left: 2.4%;
    margin-right: 0%;
    padding: 10px 10px;
    border-radius: 20px;
    margin-top: 1.6%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.08);
}

.cardparagraphupdates{
    text-align: justify!important;
    color: #333!important;
    font-weight: 200!important;
    font-size: 14px!important;
}
.tun{
    background-color: #333;
    padding: 5px 20px!important;
}

.paragraph-word-limit {
    white-space: nowrap;
    /* Prevent text from wrapping to the next line */
    overflow: hidden;
    /* Hide overflowed text */
    text-overflow: ellipsis;
    /* Show an ellipsis (...) when text is clipped */
    max-width: 85%;
    /* Limit the paragraph to its container width */
}

/* Mobile */
@media (max-width: 575px) {
    .myactiveplans-orderhistory-content {
        padding-left: 10px;
        padding-right: 10px;
    }
}