/* VAData.css */

.mynewplan_vadate_dialog-background {
    background-color: rgba(0, 0, 0, 0.12) !important;
    padding: 0px !important;
    margin: 0px !important;
}


.mynewplan_va_data_main_content {
    display: flex;
    justify-content: space-between;
    height: 80vh;
    overflow: auto;
    gap: 30px;
    z-index: 14 !important;
}

.mynewplan_va_data_main_content p{
    color: rgb(0, 0, 0, 0.6);
    font-size: 14px!important;
    text-align: justify;
}

.mynewplan_va_data_main_document {
    width: 490px;
    height: 76vh;
    margin-bottom: 4vh;
    flex-shrink: 0;
    border-radius: 30px;
    background: #ECEFF1;
    overflow: auto;
    /* Enable scrolling for overflown content */
}

.mynewplan_va_data_main_qualifiction {
    width: 480px;
    height: 76vh;
    flex-shrink: 0;
    border-radius: 30px;
    background: #ECEFF1;
    overflow: auto;
    /* Enable scrolling for overflown content */
}

.mynewplan_va_data_main_profile {
    width: 430px;
    height: 76vh;
    background: #ECEFF1;
    overflow: hidden;
    border-radius: 30px;
    overflow: auto!important;
}

.mynewplan_va_data_image img {
    height: 80px !important;
    width: 80px !important;
    border-radius: 50% !important;
}

.mynewplan_va_contact_data {
    display: flex !important;
    justify-content: space-between !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.mynewplan_va_contact_hr {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.mynewplan_va_contact_data_btn {
    padding-top: 30px !important;
}

.mynewplan_va_contact_data_btn button {
    font-size: 14px !important;
    margin-bottom: 50px !important;
}

.mynewplan_va_data_paragraph_title p {
    color: black !important;
    font-weight: 600 !important;
    padding-top: 20px;
}

.mynewplan_va_contact_data_paragraph_title p {
    font-weight: 600 !important;
    color: black !important;
    padding-top: 20px;
    text-align: center !important;
}

.mynewplan_va_contact_data_paragraph_body {
    font-weight: 400 !important;
    color: black !important;
    min-height: 50vh;
    overflow: auto;
    /* Enable scrolling for overflown content */
}

.mynewplan_va_contact_data_paragraph_body p{
    text-align: justify!important;
    color: rgba(0, 0, 0, 0.647) !important;
    line-height: 30px!important;
}

.success-message_main{
    margin-bottom: 3px!important;
    color: red!important;
}

/* ... rest of your CSS ... */

/* Mobile */
@media (min-width: 30px) and (max-width: 480px) and (orientation: portrait) {}

/* Small devices (portrait) */
@media (min-width: 481px) and (max-width: 600px) and (orientation: portrait) {}