.register_admindashboard{
    background: #F5F5F5;
}
.register_Adminregister_card{
  margin-left: 24%; 
  padding-top: 40px!important; 
}
.register_dashboardnav{
    position: sticky;
    top: 0;
    width: 100%;
}

/* Mobile */
@media (min-width:0px) and (max-width: 450px) {
  .plans_orderhistory-content {
    padding-left: 10px;
    padding-right: 10px;
  }
  .register_Adminregister_card{
    margin-left: 0px!important;
  }
}

@media (min-width:451px) and (max-width: 575px) {
  .plans_orderhistory-content {
    padding-left: 10px;
    padding-right: 10px;
  }
    .register_Adminregister_card {
      margin-left: 0px !important;
    }
}