.register_contact_form_outline{
    /* background: #F5F5F5; */
    /* padding-top: 20px; */
    padding-bottom: 80px;
    padding-left: 60px;
    padding-right: 60px;
    text-align: center !important;
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    /* margin-left: 25%; */
}

.register_main_register_form_update{
    padding-left: 60px;
    font-size: 24px;
    padding-top: 20px!important;
    /* padding-top: 25px!important; */
    padding-bottom: 45px!important;
    font-weight: 800;
    /* margin-left: 25%; */
}

.register_contact_input_field input{
    margin: 10px;
    width: 70%!important;
    margin-left: 20px!important;
}
/* .mcontactimg img {
    width: 650px;
    height: 600px;
    flex-shrink: 0;
    border-radius: 30px;
} */
.register_register_form{
    width: 635px;
    height: 630px;
    flex-shrink: 0;
    border-radius: 15.213px;
    background: #ECEFF1;
    box-shadow: 0px 5.77999px 28.89997px 4.81666px rgba(0, 0, 0, 0.12);
    padding: 30px;
}
.mtpfi{
    font-size: 30px;
    font-weight: 800;
    text-align: center;
}

.register_contact_input_field{
    padding: 1px 0px;
    padding-bottom: 10px;
}

.register_contact_input_field input{
    display: inline-flex;
    padding: 10.45px 114.64px 10.607px 19.266px;
    align-items: center;
    border-radius: 13.267px;
    margin-left: 3px;
    border: 0.963px solid rgba(97, 97, 97, 0.60);
}

.contactinputselect{
    display: inline-flex;
    padding: 9.45px 70.64px 9.607px 19.266px;
    align-items: center;
    border-radius: 13.267px;
    margin-left: 30px;
    border: 0.963px solid rgba(97, 97, 97, 0.60);
}

.contacttextareainput{
    display: flex;
    width: 175.006px;
    height: 125.233px;
    padding: 14.45px 417.74px 86.783px 19.266px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 19.267px;
    border: 0.963px solid rgba(97, 97, 97, 0.60);
}

.contactcountryo{
    padding-top: 1px;
    padding-bottom: 20px;
}

.register_contact_textarea{
    margin-left: 1px;
    padding-top: 10px;
}

.contactpar{
    margin-left: 30px;
    padding-bottom: 40px;
    padding-top: 20px;
}

.register_contact_button{
    text-align: center;
    padding-top: 35px;
}

.register_contact_form_paragraph{
    margin-left: 1px;
    font-weight: 600;
}

.contactsrpasno{
    font-weight: 800;
}

.contactsrpasnoi{
    background: var(--Button-Hover-color, linear-gradient(96deg, rgba(36, 20, 19, 0.77) -10.44%, rgba(31, 106, 88, 0.77) 108.53%, rgba(1, 255, 209, 0.77) 108.54%, rgba(3, 255, 195, 0.77) 108.87%, rgba(11, 255, 196, 0.77) 108.87%, rgba(13, 253, 195, 0.77) 108.87%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* font-size: 19.267px; */
    font-style: normal;
    font-weight: 400;
    /* line-height: 23.12px; */
}

.register_contact_button{
    text-align: center;
    padding-top: 10px;
    border: none!important;
}


/* dialog box */

/* Add these styles to your CSS file */
.dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    z-index: 2;
    display: none;
    /* Hidden by default */
    justify-content: center;
    align-items: center;
}

.dialog.show {
    display: flex;
    /* Display the dialog when 'show' class is applied */
}

.dialog-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 90%;
}

.dialog-content .close {
    /* position: absolute; */
    position: sticky;
    top: 5px;
    right: 10px;
    cursor: pointer;
}

/* Apply a blur effect to the background content */
.blur-background {
    filter: blur(4px);
    /* Adjust the blur radius as needed */
}

.register_contact_form_btn{
    border: none;
}

.register_form_paragraph_error{
    text-align: center;
    padding-bottom: 10px;
    text-decoration: dotted;
}

/* Mobile */
@media (min-width: 0px) and (max-width: 450px){
    .register_contact_form_outline {
            /* background: #F5F5F5; */
            /* padding-top: 20px; */
            padding-bottom: 80px;
            padding-left: 10px !important;
            padding-right: 10px !important;
            text-align: center !important;
            /* display: flex;
        justify-content: space-between;
        align-items: center; */
            /* margin-left: 25%; */
        }
    
        .register_main_register_form_update {
            padding-left: 10px!important;
            font-size: 20px;
            padding-top: 20px !important;
            /* padding-top: 25px!important; */
            padding-bottom: 45px !important;
            font-weight: 800;
            /* margin-left: 25%; */
        }
        .register_contact_input_field input {
            margin: 10px;
            width: 80% !important;
            margin-left: 0px !important;
            display: inline-flex;
            padding: 10.45px 0px 10.607px 19.266px;
            align-items: center;
            border-radius: 13.267px;
            margin-left: 3px;
            border: 0.963px solid rgba(97, 97, 97, 0.60);
        }
        .register_register_form {
            width: 83%!important;
            height: auto!important;
            flex-shrink: 0;
            border-radius: 15.213px;
            background: #ECEFF1;
            box-shadow: 0px 5.77999px 28.89997px 4.81666px rgba(0, 0, 0, 0.12);
            padding: 30px;
        }
}

@media (min-width: 451px) and (max-width: 575px) {
    .register_contact_form_outline {
        /* background: #F5F5F5; */
        /* padding-top: 20px; */
        padding-bottom: 80px;
        padding-left: 10px !important;
        padding-right: 10px !important;
        text-align: center !important;
        /* display: flex;
        justify-content: space-between;
        align-items: center; */
        /* margin-left: 25%; */
    }

    .register_main_register_form_update {
        padding-left: 10px !important;
        font-size: 20px;
        padding-top: 20px !important;
        /* padding-top: 25px!important; */
        padding-bottom: 45px !important;
        font-weight: 800;
        /* margin-left: 25%; */
    }

    .register_contact_input_field input {
        margin: 10px;
        width: 80% !important;
        margin-left: 0px !important;
        display: inline-flex;
        padding: 10.45px 0px 10.607px 19.266px;
        align-items: center;
        border-radius: 13.267px;
        margin-left: 3px;
        border: 0.963px solid rgba(97, 97, 97, 0.60);
    }

    .register_register_form {
        width: 88% !important;
        height: auto !important;
        flex-shrink: 0;
        border-radius: 15.213px;
        background: #ECEFF1;
        box-shadow: 0px 5.77999px 28.89997px 4.81666px rgba(0, 0, 0, 0.12);
        padding: 30px;
    }
}