/* Card.css */
.pcard {
  transition: transform 0.3s;
  text-align: center;
  width: 451px;
  height: 484px;
  margin: 20px;
  padding: 0px 60px 0px 60px;
  /* justify-content: flex-end; */
  align-items: center;
  padding-top: 10px;
  /* flex-shrink: 0; */
  text-align: center;
  border-radius: 30px;
  background: rgba(236, 239, 241, 0.60);
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.08);
  z-index: 1!important;
}

.pcard:hover {
  transform: scale(1.1);
  /* Enlargement effect on hover */
  background: rgba(196, 224, 242, 0.60);
  /* Penumber */
  box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  z-index: 2!important;
}

.pwebTop {
  font-weight: 800;
  font-size: large;
}
.webimgp{
  height: 56px;
  width: 56px;
}

@media (max-width: 575px){
  .pcard{
    width: 94%!important;
    height: auto!important;
    margin: 0px 0px!important;
    padding: 0px 10px 0px 10px !important;
  }

  .pcard:hover{
    box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.12);
  }

    .webimgp {
      height: 36px;
      width: 36px;
    }
    .pwebTop{
      font-size: 20px;
    }
    .ptopWeb{
      font-size: 15px;
    }
}