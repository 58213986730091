.creditcard{
    width: 100%;
    height: auto;
    flex-shrink: 0;
    padding: 25px 14px 22px 15px;
    border-radius: 20px;
    margin-top: 3%;
    margin-bottom: 5%;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}

.paragraph-payment-options{
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    color: rgb(33, 20, 20);
}

.paragraph-option-titles{
    color: #000;
    font-size: 19px;
    font-style: normal;
    font-weight: 800;
    line-height: 27.927px;
}
.credicard-active-options{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
}

.credit-card-hr{
    width: auto;
    height: 0.4px!important;
    background: rgba(0, 0, 0, 0.40);
}
.creditcard-options{
    padding-left: 100px;
    padding-right: 114px;
}
.credicard-active-option{
    padding-left: 90px;
    padding-right: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tan{
    background: var(--Button-Hover-color, linear-gradient(96deg, rgba(36, 20, 19, 0.77) -10.44%, rgba(31, 106, 88, 0.77) 108.53%, rgba(1, 255, 209, 0.77) 108.54%, rgba(3, 255, 195, 0.77) 108.87%, rgba(11, 255, 196, 0.77) 108.87%, rgba(13, 253, 195, 0.77) 108.87%))!important;
}
.credicard-active-options img{
    height: 35px;
    width: 35px;
}
.credicard-active-options a{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.credicard-active-options p{
    font-size: 13px!important;
}

.dialog-box-elevation span{
    color: green;
}

.paypal_input_main {
    display: flex;
    padding: 10px 5px 10px 10px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 15px;
    margin-left: 5%;
    border: 1px solid rgba(0, 0, 0, 0.40);
    background: #FFF;
    margin-bottom: 7%;
}

.success-dialog-paragraph_sure p{
    color: green;
}
.credicard-active-options span{
    padding-left: 10px;
}

/* Mobile */
@media (min-width: 30px) and (max-width: 480px) and (orientation: portrait) {}

/* Small devices (portrait) */
@media (min-width: 481px) and (max-width: 600px) and (orientation: portrait) {}