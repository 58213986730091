.plancard {
    border-radius: 30px;
    background: #FFF;
    width: 100%;
    height: auto;
    padding: 60px 20px;
}

.placecards{
    display: flex;
    padding-bottom: 25px;
    justify-content: space-between;
}

.planserve {
    /* padding-top: 10px; */
    width: 336px;
    height: 300px;
    margin-right: 2.5%;
    margin-left: 2.5%;
    flex-shrink: 0;
    text-align: center;
    border-radius: 30px;
    background: rgba(236, 239, 241, 0.60);
}

.servicecards{
    width: 430px;
    height: 300px;
    margin-right: 0.2%;
    margin-left: 0.2%;
    flex-shrink: 0;
    text-align: center;
    border-radius: 30px;
    background: rgba(236, 239, 241, 0.60);
}

.servicecards:hover{
    background: rgba(196, 224, 242, 0.60);
}

.service-paragraph{
    text-align: justify;
    color: rgb(0, 0, 0, 0.6);
    padding-right: 12%!important;
}

.service-paragraph p{
    font-size: 15px!important;
    line-height: 30px;
}

.planserve:hover {
    /* padding-top: 10px; */
    width: 336px;
    height: 300px;
    text-align: center;
    flex-shrink: 0;
    border-radius: 30px;
    background: rgba(196, 224, 242, 0.60);
}

.servp {
    font-weight: 400 !important;
    font-size: 18px !important;
}

.servpe {
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 30px;
}

.servc {
    padding-right: 10px;
    height: 25px;
    width: 25px;
}

.spy {
    /* display: flex; */
    display: flex;
    align-items: center !important;
    align-items: center;
    padding-right: 20px;
    padding-left: 60px;
}

.serhr {
    width: 163.003px;
    height: 1px;
    background: linear-gradient(96deg, rgba(127, 226, 80, 0.77) -10.44%, rgba(11, 255, 196, 0.77) 108.53%, rgba(11, 138, 255, 0.77) 108.54%);
}

.servpi {
    font-weight: 800;
}

/* Mobile */
@media (min-width:0px) and (max-width: 450px) {
    .orderhistory-content {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (min-width:451px) and (max-width: 575px) {
    .orderhistory-content {
        padding-left: 10px;
        padding-right: 10px;
    }
}