.orderhistory {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: #f2f2f2;
}

.orderhistory-content {
  padding-top: 20px !important;
  padding-bottom: 65px !important;
}

.admin_client_orderhistory-content {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.orderhistory .orderhistory-content {
  flex-grow: 1;
  padding: 0px 30px;
}
.orderhistory .orderhistory-content .topContainer {
  margin-bottom: 25px;
}
.orderhistory .orderhistory-content .topContainer .buttonsBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.orderhistory .orderhistory-content .topContainer .buttonsBar .placeorder_btn {
  background: linear-gradient(to right, #24cd48, #57eaaa);
  color: black;
  border-radius: 12px;
  padding: 8px 12px;
  font-weight: bold;
}
.orderhistory .orderhistory-content .topContainer .buttonsBar .links_group {
  background-color: #333;
  color: white;
  padding: 4px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.orderhistory .orderhistory-content .topContainer .buttonsBar .links_group .link:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.888);
}
.orderhistory .orderhistory-content .tableContainer {
  padding: 12px;
  background-color: white;
  border-radius: 12px;
}
.orderhistory .orderhistory-content .tableContainer .filter-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.orderhistory .orderhistory-content .tableContainer .filter {
  display: flex;
  flex-direction: row;
  gap: 3px;
}
.orderhistory .orderhistory-content .tableContainer .filter label {
  margin-right: 8px;
}
.orderhistory .orderhistory-content .tableContainer .search input {
  padding: 1px 5px;
}
.orderhistory .orderhistory-content .tableContainer table {
  width: 100%;
  border-collapse: collapse;
}
.orderhistory .orderhistory-content .tableContainer table th, .orderhistory .orderhistory-content .tableContainer table td {
  padding: 8px;
  border: 1px solid #ccc;
  text-align: left;
}
.orderhistory .orderhistory-content .tableContainer table th {
  background-color: #f2f2f2;
}
.orderhistory .orderhistory-content .tableContainer table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.orderhistory .orderhistory-content .tableContainer .pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  font-size: 14px;
  color: #333;
}
.orderhistory .orderhistory-content .tableContainer .pagination .pagination-buttons button {
  margin-left: 8px;
}
.orderhistory .orderhistory-content .tableContainer .pagination .pagination-buttons button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.orderhistory .orderhistory_footer {
  position: absolute;
  bottom: 0;
}

.order_links_group_content{
  font-size: 13px!important;
}

/* Mobile */
@media (min-width:0px) and (max-width: 450px) {
  .admin_client_orderhistory-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width:451px) and (max-width: 575px) {
  .admin_client_orderhistory-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}