.matheprice {
  background-image: url('../../images/vmenthree.jpg');
  /* Replace 'your-image.jpg' with the path to your image */
  background-color: rgba(0, 0, 0, 0.62);
  /* Red color with 60% opacity */
  background-blend-mode: overlay;
  /* Blend the background image and color */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 500px;
  flex-shrink: 0;
}

.maprice {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 20!important;
}

.mapricecontent {
  color: var(--White-color, #FFF);
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  padding-top: 176px;
}
.mapricecontent p{
  line-height: 55px;
}

.spancolor1 {
  background: var(--Button-Color, linear-gradient(96deg, rgba(127, 226, 80, 0.77) -10.44%, rgba(11, 255, 196, 0.77) 108.53%, rgba(11, 138, 255, 0.77) 108.54%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 34px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
}

/* Mobile */
@media (max-width: 575px) {
  .mapricecontent{
    font-size: 30px!important;
  }
}