.admin_dashboards_main {
    background: #F5F5F5;
    min-height: 100vh;
    padding: 0px!important;
    margin: 0px!important;
}

.admin_dashboards_main_navbar{
    position: sticky;
    top: 0;
    width: 100%;
}

.admin_dashboards_content_title{
    display: block!important;
    padding-left: 75px;
    padding-right: 75px;
    padding-top: 0px;
    padding-bottom: 50px;
}

.admin_dashboards_cards{
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
}

/* Mobile */
@media (min-width:0px) and (max-width: 450px) {
    .admin_dashboards_content_title {
        padding-left: 10px;
        padding-right: 10px;
    }
    .admin_dashboards_cards{
        display: block;
    }
}

@media (min-width:451px) and (max-width: 575px) {
    .admin_dashboards_content_title {
        padding-left: 10px;
        padding-right: 10px;
    }
        .admin_dashboards_cards {
            display: block;
        }
}