.vadate_dialog-background_profile_main{
    background-color: rgb(0, 0, 0, 0.8)!important;
}
.va_data_main_profile_main_profile{
    height: 80vh!important;
}

.va_data_main_profile_main_profile p{
   font-weight: 400!important;
}

.vadate_dialog-background_profile{
    background-color: rgb(0, 0, 0, 0.8) !important;
}

/* Mobile */
@media (min-width:0px) and (max-width: 450px) {
    .orderhistory-content {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (min-width:451px) and (max-width: 575px) {
    .orderhistory-content {
        padding-left: 10px;
        padding-right: 10px;
    }
}