.carousel {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.carousel-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    background-color: rgba(0, 0, 0, 0.62);
    /* Black color with 62% opacity */
    background-blend-mode: overlay;
}

.carousel-image.active {
    opacity: 1;
}

.carousel-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: white;
    text-align: center;
    /* Add any additional styles for your content */
}


.mamlandingcontent_main-paragraph{
    line-height: 40px!important;
}