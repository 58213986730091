.notification {
    display: none;
    padding: 10px;
    color: #fff;
    border-radius: 4px;
    margin: 10px 0;
    transition: 0.5s;
    max-width: 300px;
    text-align: center;
    font-weight: bold;
}

.notification.success {
    background-color: #4CAF50;
}

.notification.error {
    background-color: #f44336;
}

/* Mobile */
@media (max-width: 575px) {}