/* VAData.css */

.vadate_dialog-background {
    background-color: rgba(0, 0, 0, 0.12) !important;
    padding: 0px !important;
    margin: 0px !important;
}

.va_data_main_content {
    display: flex;
    justify-content: space-between;
    height: 80vh;
    overflow: auto;
    gap: 30px;
    z-index: 14 !important;
}

.va_data_main_profile {
    width: 380px;
    /* Adjust the width as needed */
    height:78vh;
    /* Adjust the height as needed */
    /* To make it a circle */
    background: #ECEFF1;
    border-radius: 30px;
    /* Ensure the image doesn't overflow the circle */
    overflow-y: auto;
}

.va_data_main_profile_main{
    height: 86vh!important;
}

.va_data_main_document_main{
    height: 41vh!important;
}

.va_data_main_qualifiction_main{
    height: 41vh!important;
}

.va_data_main_document {
    width: 420px;
    height: 37vh;
    margin-bottom: 4vh;
    /* Adjust the height as needed */
    flex-shrink: 0;
    border-radius: 30px;
    background: #ECEFF1;
    overflow-y: auto;
}

.va_data_main_qualifiction {
    width: 412px;
    height: 37vh;
    /* Adjust the height as needed */
    flex-shrink: 0;
    border-radius: 30px;
    background: #ECEFF1;
    overflow-y: auto;
}

.assistant_va_data_image{
    padding-top: 40px!important;
    padding-bottom: 20px!important;
}

.assistant_va_data_image img {
    height: 85px!important;
    width: 85px!important;
    border-radius: 50%!important;
    border: 3px solid white;
    /* width: 100%!important;
    height: 100%!important; */
    margin: 0px!important;
    padding: 0px!important;
}

.assistant_va_contact_data{
    display: flex!important;
    justify-content: space-between!important;
    padding-left: 20px!important;
    padding-right: 20px!important;
}

.assistant_va_contact_hr{
   padding-left: 20px !important;
   padding-right: 20px !important;
}
.assistant_va_contact_data_btn{
    padding-top: 8px!important;
}

.assistant_va_contact_data_btn button{
    font-size: 14px!important;
    margin-bottom: 0px!important;
}

.assistant_va_data_paragraph_title p{
    color: black!important;
    font-weight: 600!important;
}

.assistant_va_contact_data_paragraph_title p{
    font-weight: 600!important;
    color: black!important;
}

/* ... rest of your CSS ... */

.assistant_va_contact_data_paragraph_body p{
    color: rgb(0, 0, 0, 0.6)!important;
}

.file_btn_download{
    padding: 0px 10px!important;
    margin-top: 2px!important;
    border: none;
    cursor: pointer;
}

/* Mobile */
@media (min-width: 0px) and (max-width: 480px)  {
    .va_data_main_profile {
            width: 100%!important;
            /* Adjust the width as needed */
            height: 78vh;
            /* Adjust the height as needed */
            /* To make it a circle */
            background: #ECEFF1;
            border-radius: 30px;
            /* Ensure the image doesn't overflow the circle */
            overflow-y: auto;
        }
.assistant_va_data_image img {
    height: 55px !important;
    width: 55px !important;
    border-radius: 50% !important;
    border: 3px solid white;
    /* width: 100%!important;
    height: 100%!important; */
    margin: 0px !important;
    padding: 0px !important;
}
.va_data_main_document {
    width: 100% !important;
    height: 37vh;
    margin-bottom: 4vh;
    /* Adjust the height as needed */
    flex-shrink: 0;
    border-radius: 30px;
    background: #ECEFF1;
    overflow-y: auto;
}

.va_data_main_qualifiction {
    wwidth: 100% !important;
    height: 37vh;
    /* Adjust the height as needed */
    flex-shrink: 0;
    border-radius: 30px;
    background: #ECEFF1;
    overflow-y: auto;
}
}

/* Small devices (portrait) */
@media (min-width: 481px) and (max-width: 575px) {
.va_data_main_profile {
        width: 100% !important;
        /* Adjust the width as needed */
        height: 78vh;
        /* Adjust the height as needed */
        /* To make it a circle */
        background: #ECEFF1;
        border-radius: 30px;
        /* Ensure the image doesn't overflow the circle */
        overflow-y: auto;
    }
.assistant_va_data_image img {
    height: 55px !important;
    width: 55px !important;
    border-radius: 50% !important;
    border: 3px solid white;
    /* width: 100%!important;
    height: 100%!important; */
    margin: 0px !important;
    padding: 0px !important;
}
.va_data_main_document {
    width: 100% !important;
    height: 37vh;
    margin-bottom: 4vh;
    /* Adjust the height as needed */
    flex-shrink: 0;
    border-radius: 30px;
    background: #ECEFF1;
    overflow-y: auto;
}

.va_data_main_qualifiction {
    width: 100% !important;
    height: 37vh;
    /* Adjust the height as needed */
    flex-shrink: 0;
    border-radius: 30px;
    background: #ECEFF1;
    overflow-y: auto;
}
}