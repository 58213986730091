.cardsignupone {
    width: 56%;
    margin-top: -10%;
    padding: 10px 30px;
    flex-shrink: 0;
    border-radius: 30px;
    background: var(--Background-onecolor, #ECEFF1);
    margin-left: 21%;
    margin-right: 22%;
    text-align: center;
    margin-bottom: 5%;
}

.cardmainsip {
    color: rgba(252, 1, 31, 0.60);
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.signcardcontent {
    text-align: start;
    margin-left: 10%;
}

.signcardcontent p {
    color: var(--Navbar-color, #616161);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.signcardcontent span {
    color: rgba(255, 11, 11, 0.5)!important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.signcardiinput {
    width: 620px;
    height: 36px;
    padding-left: 15px;
    flex-shrink: 0;
    border-radius: 15px;
    border: 0.6px solid rgba(0, 0, 0, 0.60);
    background: var(--Background-onecolor, #fff);
}

.signcardsub {
    text-align: center;
    margin-bottom: 5%;
}

.signcardcontentir {
    margin-left: 9%;
}

.file-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 80.5%;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 15px;
    margin-top: 10px;
    font-size: 14px;
    overflow: hidden;
}

.file-label img{
    height: 24px;
    width: 24px;
}

.hidden-input {
    display: none;
}

.file-info {
    margin-left: 10px;
    font-size: 13px;
    display: flex;
    gap: 10px;
}

.selected-file {
    margin-top: 5px;
    color: #333;
}

.no-file-chosen {
    margin-top: 5px;
    color: #888;
}

.signcardcontentir Link{
    font-style: italic;
}

/* Mobile */
@media (max-width: 575px){
    
}