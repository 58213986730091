.mlandfirst {
    padding-left: -32px;
    padding-right: 32px;
    background: var(--Background-onecolor, #ECEFF1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.mlandsecond {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    align-items: center;
}

.mlandpone {
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
    /* 100% */
}

.mlandcolors {
    background: var(--Button-Color, linear-gradient(96deg, rgba(127, 226, 80, 0.77) -10.44%, rgba(11, 255, 196, 0.77) 108.53%, rgba(11, 138, 255, 0.77) 108.54%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
}

.mlandptwo {
    text-align: justify;
    line-height: 35px;
    padding-left: 7px;
    padding-right: 20px;
}

.mlandprice {
    text-align: center;
}

.mlandpthree {

    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    background: var(--Button-Hover-color, linear-gradient(96deg, rgba(36, 20, 19, 0.77) -10.44%, rgba(31, 106, 88, 0.77) 108.53%, rgba(1, 255, 209, 0.77) 108.54%, rgba(3, 255, 195, 0.77) 108.87%, rgba(11, 255, 196, 0.77) 108.87%, rgba(13, 253, 195, 0.77) 108.87%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.mlandtitle {
    text-align: center;
    padding-top: 3px;
}

.mlandfsec {
    padding-right: -200px;
}

.tanlando{
    text-align: center;
}

/* Mobile */
@media (max-width: 575px) {
    .mlandfirst {
            padding-left: 10px;
            padding-right: 10px;
            background: var(--Background-onecolor, #ECEFF1);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 20px;
        }
    
        .mlandsecond {
            padding-left: 20px;
            padding-right: 20px;
            text-align: center;
            align-items: center;
        }
    
        .mlandpone {
            color: #000;
            font-size: 25px;
            font-style: normal;
            font-weight: 800;
            line-height: 40px;
            /* 100% */
        }
    
        .mlandcolors {
            background: var(--Button-Color, linear-gradient(96deg, rgba(127, 226, 80, 0.77) -10.44%, rgba(11, 255, 196, 0.77) 108.53%, rgba(11, 138, 255, 0.77) 108.54%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 26px;
            font-style: normal;
            font-weight: 800;
            line-height: 40px;
        }
    
        .mlandptwo {
            text-align: justify;
            line-height: 35px;
            padding-left: 7px;
            padding-right: 7px;
            font-size: 14px;
        }
    
        .mlandprice {
            text-align: center;
        }
    
        .mlandpthree {
    
            font-size: 30px;
            font-style: normal;
            font-weight: 800;
            background: var(--Button-Hover-color, linear-gradient(96deg, rgba(36, 20, 19, 0.77) -10.44%, rgba(31, 106, 88, 0.77) 108.53%, rgba(1, 255, 209, 0.77) 108.54%, rgba(3, 255, 195, 0.77) 108.87%, rgba(11, 255, 196, 0.77) 108.87%, rgba(13, 253, 195, 0.77) 108.87%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    
        .mlandtitle {
            text-align: center;
            padding-top: 3px;
        }
    
        .mlandfsec {
            padding-right: -200px;
            display: none;
        }
    
        .tanlando {
            text-align: center;
        }
}