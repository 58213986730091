.industry-owner{
    padding-left: 70px;
    padding-right: 70px;
    background: var(--Background-onecolor, #ECEFF1);
    padding-top: 10px;
    padding-bottom: 40px;
}

.industry-owmain{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.industry-ownerimg{
    padding-top: 50px;
    width: 500px;
    height: 500px;
    border-radius: 50%;
}

.industry-oimg{
    padding-top: 20px;
}

.industry-oimgs{
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
}

.industry-secondp{
    line-height: 35px;
    text-align: justify;
    padding-left: 20px;
    padding-right: 20px;
}

.industry-firstp{
    font-size: 24.5px;
    font-weight: 800;
}

.industry-firstp span{
    font-size: 24.5px;
    font-weight: 800;
}

/* Mobile */
@media (max-width: 575px){
    .industry-oimg{
        display: none;
    }
        .industry-owner {
            padding-left: 10px;
            padding-right: 10px;
        }
}
