.mainfundcontent{
    padding-left: 75px;
    padding-right: 105px;
}

/* Mobile */
@media (min-width:0px) and (max-width: 450px) {
    .mainfundcontent {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (min-width:451px) and (max-width: 575px) {
    .mainfundcontent {
        padding-left: 10px;
        padding-right: 10px;
    }
}