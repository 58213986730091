.chat{
    height: 78vh!important;
    background-color: #DDE6ED!important;
    /* margin-left: 6%!important; */
    margin-right: 14%!important;
}

.chat_info{
    color: #daf0da!important;
}

.chat_input_parent{
    color: red;
}

.chats_sidebar {
    color: red !important;
    background-color: #fff !important;
    height: 78vh !important;
    margin-left: 8% !important;
    /* border: 1px solid gray; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Adjust the values as needed */
}

.chat_input_parent{
    /* background-color: red!important;
    height: 3vh!important; */
}

.chat_input textarea{
    background-color: #fff !important;
    height: 5vh!important;
    width: 80%!important;
    margin-left: 3%;
    margin-top: 1%;
    border-radius: 1%;
    border: none!important;
}

.send_input_chats{
    height: 5vh !important;
    display: flex;
    align-items: center;
    margin-right: 2%;
    margin-left: 2%;
}

.chat_input_parent_group{
    
}

.chat_input{
    display: flex;
    justify-content: space-between;
    align-items: center!important;
}

.selected_files{
    display: flex!important;
}

.chat_input_parent_group{
    /* background-color: red!important;
    padding-bottom: 27px!important; */
}

.input_chat_btn{
    padding: 5px 13px;
}

.serch_form_input input{
    font-size: 14px!important;
}

.user_chat_info_span span {
    font-size: 15px !important;
    color: rgba(51, 51, 51, 0.8) !important;
    display: block;
    overflow: hidden;
    width: 20vw!important;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.user_chats_span span:hover{
    cursor: pointer!important;
}

.user_chat_names{
    color: green!important;
}

/* Mobile */
@media (min-width: 0px) and (max-width: 480px){
    .chat {
            height: 78vh !important;
            background-color: #DDE6ED !important;
            /* margin-left: 6%!important; */
            margin-right: 0% !important;
        }
        .user_chat_info_span span {
            font-size: 13px !important;
            color: rgba(51, 51, 51, 0.8) !important;
            display: block;
            overflow: hidden;
            width: 14vw !important;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    .chat_input_parent_group{
        display: block!important;
    }
 .chat_input textarea {
     background-color: #fff !important;
     height: 5vh !important;
     width: 100% !important;
     margin-left: 0%;
     margin-top: 1%;
     border-radius: 1%;
     border: none !important;
 }
 .chats_sidebar {
     color: red !important;
     background-color: #fff !important;
     height: 78vh !important;
     margin-left: 0% !important;
     /* border: 1px solid gray; */
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
     /* Adjust the values as needed */
 }
 .chatsbar{
    margin: 0px!important;
    padding: 0px!important;
 }
}

/* Small devices (portrait) */
@media (min-width: 481px) and (max-width: 575px){
    .chat {
            height: 78vh !important;
            background-color: #DDE6ED !important;
            /* margin-left: 6%!important; */
            margin-right: 0% !important;
        }
        .user_chat_info_span span {
            font-size: 13px !important;
            color: rgba(51, 51, 51, 0.8) !important;
            display: block;
            overflow: hidden;
            width: 14vw !important;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
                .chat_input_parent_group {
                    display: block !important;
                }
         .chat_input textarea {
             background-color: #fff !important;
             height: 5vh !important;
             width: 100% !important;
             margin-left: 0%;
             margin-top: 1%;
             border-radius: 1%;
             border: none !important;
         }
        .chats_sidebar {
            color: red !important;
            background-color: #fff !important;
            height: 78vh !important;
            margin-left: 0% !important;
            /* border: 1px solid gray; */
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            /* Adjust the values as needed */
        }
        .chatsbar {
            margin: 0px !important;
            padding: 0px !important;
        }
}