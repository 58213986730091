/* Card.css */
.Mlandcard {
    /* width: 200px;
    padding: 20px; */
    /* background-color: #f0f0f0; */
    transition: transform 0.3s;
    text-align: center;
    /* border: 1px solid #ccc; */
    width: 310px;
    height: 230px;
    padding: 10px 10px 10px 10px;
    /* justify-content: flex-end; */
    align-items: center;
    padding-top: 10px;
    /* flex-shrink: 0; */
    text-align: center;
    border-radius: 30px;
    background: rgba(236, 239, 241, 0.60);
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.08);
}

.Mlandcard:hover {
    transform: scale(1.1);
    /* Enlargement effect on hover */
    background: rgba(196, 224, 242, 0.60);
    /* Penumber */
    box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.MlandTop {
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
}

.Mlandtop{
    color: #000;
    text-align: center;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 33.531px;
}

.Mlandimg{
    height: 45px;
    width: 45px;
    margin-top: 25px;
}

.mlaparass{
    font-weight: 500;
}

/* Small devices (portrait) */
@media (max-width: 575px){
   .Mlandcard {
    width: 96%;
    height: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
    }
        .Mlandimg {
            height: 35px;
            width: 35px;
            margin-top: 15px;
        }
        .MlandTop {
            font-weight: 400;
            font-size: 14px;
            line-height: 30px;
        }
}