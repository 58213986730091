.dashcard {
  text-align: center;
  width: 400px;
  height: 230px;
  padding: 10px;
  margin-bottom: 30px;
  align-items: center;
  padding-top: 50px;
  text-align: center;
  border-radius: 30px;
  background: #FFF;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.08);
  float: left;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.dashcard:hover {
  /* background: rgb(149, 149, 225); */
  /* background: rgb(179, 176, 176); */
  /* Darkish background color on hover */
  /* box-shadow: 0px 8px 20px 0px rgba(179,
    176,
    176, 0.2); */
  /* Adjusted shadow */

  background: rgba(196, 224, 242, 0.60);
  /* Penumber */
  box-shadow: 0px 6px 30px 5px rgba(196,
      224,
      242, 0.12);
}

.dashcard p {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 800;
}

.dashcard img{
  height: 48px;
  width: 48px;
}
/* You can adjust the button styles as needed. */
.empty {
  text-align: center;
  width: 400px;
  height: 220px;
  padding: 10px 10px;
  margin-bottom: 30px;
  margin-right: 30px;
  align-items: center;
  padding-top: 70px;
  text-align: center;
  border-radius: 30px;
  float: left;
}

.ton {
  border: none;
}

.ton-tin {
  font-weight: normal;
  font-size: 15px;
}

/* Mobile */
@media (max-width: 575px){
  .dashcard{
    width: 94.8%;
    height: auto;
  }
}