.cardbtn{
    display: flex;
    width: 100%;
    padding: 25px 14px 22px 15px;
    justify-content: center;
    align-items: flex-start;
    gap: 49px;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    justify-content: space-around;
    
}

.dialog-box-elevation{
    z-index: 13!important;
}

/* Mobile */
@media (min-width: 30px) and (max-width: 480px) and (orientation: portrait) {}

/* Small devices (portrait) */
@media (min-width: 481px) and (max-width: 600px) and (orientation: portrait) {}