.dialog-box-elevation_assign{
    color: #333;
    width: 400px;
    height: 450px;
    font-size: 12px;
    overflow: auto;
}

.dialog-box-elevation_assign button{
    padding-left: 11px!important;
    padding-bottom: 6px !important;
    padding-right: 11px !important;
    padding-top: 6px !important;
    font-size: 11px!important;
    margin-left: 14px!important;
}

.dialog-box-elevation_assign ol{
    text-align: start!important;
    padding: 0 !important;
    margin: 0 !important;
}

.dialog-box-elevation_assign li{
    padding: 0!important;
    margin: 0!important;
}

/* Mobile */
@media (min-width: 30px) and (max-width: 480px) and (orientation: portrait) {}

/* Small devices (portrait) */
@media (min-width: 481px) and (max-width: 600px) and (orientation: portrait) {}
