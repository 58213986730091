.induowner {
    padding-left: 70px;
    padding-right: 70px;
    background: #F5F5F5;
    padding-top: 10px;
    padding-bottom: 40px;
}

.induowmain {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.induownerimg {
    padding-top: 40px;
    width: 504px;
    height: 500px;
    border-radius: 50%;
}

.induoimgs {
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
}

.indusecondp {
    line-height: 35px;
    text-align: justify;
    padding-left: 20px;
    padding-right: 20px;
}

.indufirstp {
    font-size: 24.5px;
    font-weight: 800;
}

.indufirstp span {
    font-size: 24.5px;
    font-weight: 800;
}

/* Mobile */
@media (max-width: 575px){
   .induoimg{
    display: none;
   }
   .induowner{
    padding-left: 10px;
    padding-right: 10px;
   }
}