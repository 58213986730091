.mathemlanding {
    /* background-image: url('../../images/elite2.jpg'); */
    /* Replace 'your-image.jpg' with the path to your image */
    background-color: rgba(0, 0, 0, 0.62);
    /* Red color with 60% opacity */
    background-blend-mode: overlay;
    /* Blend the background image and color */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    flex-shrink: 0;
    z-index: 20!important;
    margin-top: -100vh!important;
}

.main-carousel{
    z-index: 1!important;
}

.mamlanding {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
}

.mamlandingcontent_main {
    color: var(--White-color, #FFF);
    text-align: center;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: 50px!important;
    padding-top: 70px;
}

.mamlandingcontent_main p {
    margin-left: 13%;
    line-height: 50px!important;
    text-align: flex-end;
    font-size: 45px;
    font-weight: bolder!;
}


/* .landing-carousel{
    margin-top: -100vh!important;
    z-index: 30!important;
} */


.Landingspancolor1 {
    background: var(--Button-Color, linear-gradient(96deg, rgba(127, 226, 80, 0.77) -10.44%, rgba(11, 255, 196, 0.77) 108.53%, rgba(11, 138, 255, 0.77) 108.54%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 45px;
    font-style: normal;
    font-weight: bolder;
    line-height:50px;
}

.mamealanding{
    text-align: center;
    padding-top: 30px;
}

.memlandcontent{
    font-size: 24px;
    color: white;
    font-weight: 600;

}

.memlandcontenti{
    font-size: 22px;
    color: white;
    font-weight: 530;
}

.tin{
    color: black!important;
}

.tin:hover{
    color: white!important;
}

.memlandcontente{
    color: rgba(255, 255, 255, 0.50);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}
.landcolrone{
    background: linear-gradient(96deg, rgba(127, 226, 80, 0.50) -10.44%, rgba(11, 255, 196, 0.50) 108.53%, rgba(11, 138, 255, 0.50) 108.54%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
}

/* Mobile */

@media (min-width: 0px) and (max-width: 400px) {
    .mamlandingcontent_main {
        font-size: 26px;
    }

    .mamlandingcontent_main p {
        font-size: 26px;
    }

    .Landingspancolor1 {
        font-size: 26px;
    }

    .memlandcontent {
        padding-top: 10px;
        font-size: 18px;
    }

    .memlandcontenti {
        padding-top: 10px;
        font-size: 16px;
    }

    .memlandcontente {
        padding-top: 6px;
        font-size: 16px;
    }

    .landcolrone {
        padding-top: 6px;
        font-size: 16px;
    }
}

@media (min-width: 401px) and (max-width: 575px) {
    .mamlandingcontent_main {
        font-size: 30px;
    }
    
    .mamlandingcontent_main p {
        font-size: 30px;
    }

    .Landingspancolor1{
        font-size: 30px;
    }

    .memlandcontent{
        padding-top: 10px;
        font-size: 18px;
    }

    .memlandcontenti{
        padding-top: 10px;
        font-size: 16px;
    }

    .memlandcontente{
        padding-top: 6px;
        font-size: 16px;
    }

    .landcolrone{
        padding-top: 6px;
        font-size: 16px;
    }
}

