
/* CardStack.css */
.industry-forth-card-stack {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 60vh; */
}

.industry-forth-card {
    width: 540px;
    height: 540px;
    margin: 10px;
    border-radius:50%;
    overflow: hidden;
}

.industry-forth-bottom-card {
    z-index: 0!important;
    border: 1px solid #ccc;
    background-image: url('../../../../images/vamenthree.jpg');
        /* Replace 'your-image.jpg' with the path to your image */
        background-color:  rgba(127, 226, 80, 0.40);

        /* Red color with 60% opacity */
        background-blend-mode: overlay;
        /* Blend the background image and color */
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 500px;
        flex-shrink: 0;
}

.industry-forth-top-card {
    /* background-color: #ffffff; */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin-top: -470px;
    margin-left: 124px;
    /* Adjust the negative margin to almost completely cover the bottom card */
    width: 540px;
    height: 540px;
    z-index: 2;
    background-image: url('../../../../images/vamanone.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 500px;
        flex-shrink: 0;
    /* Bring the top card above the bottom card */
}

/* Mobile */
@media (max-width: 575px){
    
}