@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400&display=swap');
* {
  font-family: 'Nunito', sans-serif;
}

/* Input glow */

textarea:focus,
input:focus,
select:focus,
input[type]:focus,
.uneditable-input:focus {
  border: 1px solid #007BFF;
  box-shadow: 0 1px 1px rgba(229, 103, 23, 0.075) inset, 0 0 8px rgb(0, 123, 255, 0.2);
  outline: 0 none;
}
