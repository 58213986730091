.client_dashboards_main{
   background: #f5f5f5;
}

.client_dashboards_navbar{
  position: sticky;
  top: 0;
  width: 100%;
}


.client_dashboards_cards{
  display: flex;
  justify-content: space-between!important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.client_dashboard_title_paragraph {
  font-size: 24px;
  font-weight: 600;
  padding-top: 30px;
  /* padding-bottom: 10px; */
}

.client_dashboard_title_paragraph span {
  background: var(--Button-Hover-color, linear-gradient(96deg, rgba(36, 20, 19, 0.77) -10.44%, rgba(31, 106, 88, 0.77) 108.53%, rgba(1, 255, 209, 0.77) 108.54%, rgba(3, 255, 195, 0.77) 108.87%, rgba(11, 255, 196, 0.77) 108.87%, rgba(13, 253, 195, 0.77) 108.87%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Mobile */
@media (min-width:0px) and (max-width: 450px) {
  .addpagecontent {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width:451px) and (max-width: 575px) {
  .addpagecontent {
    padding-left: 10px;
    padding-right: 10px;
  }
}