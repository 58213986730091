.Pour{
  display: flex;
  justify-content: space-between;
  background:  #ECEFF1;
  padding-left: 32px;
  padding-right: 32px;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
}

.Pserv{
  display: flex;
  justify-content: space-between;
}

.ptopWeb{
  text-align: justify;
  line-height: 35px;
}

.Pours p{
  font-weight: 800;
  font-size: 30px;
  /* padding-left: 90px; */
}

.Pours span{
  font-size: 30px;
}

/* Mobile */
@media (max-width: 575px) {
  .Pour{
    display: block;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }

  .Pserv{
    display: block!important;
  }

  .Pours{
    font-size: 26px!important;
  }
}