.adminnavbar{

}
.adminlogo{
    gap: 20px !important;
}
.adminnavbar img{
    height: 27px;
    width: 27px;
}
.adminnavbartop{
    height: 30px;
    padding-left: 32px;
    padding-right: 56px;
    align-items: center;
    padding-top: 10px;
    color: white;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    background: #001C30;
}

.adminnavbarbottom{
    display: flex;
    height: 24px;
    padding-top: 18px;
    padding-bottom: 12px;
    align-items: center;
    padding-left: 32px;
    gap: 100px!important;
    justify-content: space-between;
    /* width: 100%; */
    padding-right: 32px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.09);
}
.adminnavbarbottom p{
    align-items: center;
    cursor: pointer;
    width: 100%!important;
}

.adminmainnavbarbottom {
    display: flex;
    height: 24px;
    padding-top: 18px;
    padding-bottom: 12px;
    align-items: center;
    padding-left: 32px;
    gap: 68px !important;
    justify-content: space-between;
    /* width: 100%; */
    padding-right: 32px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.09);
}

.adminmainnavbarbottom p {
    align-items: center;
    cursor: pointer;
    width: 100% !important;
}

.adminlink{
    text-decoration: none;
    font-weight: 400!important;
    color: black;
}

.adminnavbartopleft{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.adminnavbartopleft p{
    padding-left: 28px;
    align-items: center;
    font-weight: 600;
}

.adminuser{
    display: flex;
    width: 100%;
    font-size: 17px;
}

.adminuser span{
    padding-left: 7px;
}

.adminuserlast{
    text-align: end!important;
}

/* .toggle-nav-btn{
    background-color: white;
    position: fixed;
    margin-top: 9%!important;
    right: 13vh;
    color: #333;
    font-weight: 400;
    z-index: 5;
    border-radius: 8px;
} */

.toggle-nav-btn {
    background-color: white;
    position: fixed;
    margin-top: 6% !important;
    right: 8vh;
    color: #333;
    font-weight: 400;
    cursor: pointer;
    z-index: 5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    /* Add box shadow */
}

.toggle-nav-btn_body_main{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin: 0px;
    padding: 5px 10px;
}

.toggle-nav-btn_body_main img{
    height: 25px!important;
    width: 25px!important;
    margin-right: 2px;
}

.toggle-nav-btn_body_main span{
    padding-right: 30px!important;
    font-size: 15px!important;
    font-weight: 400!important;
}

.adminuser_logo{
    cursor: pointer;
}

.adminnavbarbottom p {
    /* display: inline-block; */
    /* Make the link inline-block to contain the underline */
    position: relative;
    /* Position relative for pseudo-element */
}


/* Define the orange underline for the navigation link */
.adminnavbarbottom p::before {
    content: '';
    /* Create an empty pseudo-element */
    width: 100%;
    height: 2px;
    /* Adjust the thickness of the underline */
    background: var(--Button-Color, linear-gradient(96deg, rgba(127, 226, 80, 0.77) -10.44%, rgba(11, 255, 196, 0.77) 108.53%, rgba(11, 138, 255, 0.77) 108.54%));
    /* Orange color for the underline */
    position: absolute;
    /* Position absolute within the relative parent */
    bottom: 0;
    /* Position it at the bottom of the link */
    left: 0;
    transform: scaleX(0);
    /* Initial scale to 0 for animation */
    transform-origin: left;
    transition: transform 0.2s ease;
    /* Add animation for the underline */
}

/* Add animation when hovering over the navigation link */
.adminnavbarbottom p:hover::before {
    transform: scaleX(1);
    /* Scale up to show the underline */
    transform-origin: left;
}

/* Add animation for exiting the hover state */
.adminnavbarbottom p::after {
    content: '';
    /* Create an empty pseudo-element */
    width: 100%;
    height: 2px;
    /* Adjust the thickness of the underline */
    background: var(--Button-Color, linear-gradient(96deg, rgba(127, 226, 80, 0.77) -10.44%, rgba(11, 255, 196, 0.77) 108.53%, rgba(11, 138, 255, 0.77) 108.54%));
    /* Orange color for the underline */
    position: absolute;
    /* Position absolute within the relative parent */
    bottom: 0;
    /* Position it at the bottom of the link */
    left: 0;
    transform: scaleX(0);
    /* Initial scale to 0 for animation */
    transform-origin: left;
    transition: transform 100s ease;
    /* Add animation for the underline */
}

/* Add animation for exiting the hover state */
.adminnavbarbottom p:hover::after {
    transform: scaleX(1);
    /* Scale up to show the underline */
    transform-origin: right;
}

.adminmainnavbarbottom p {
    /* display: inline-block; */
    /* Make the link inline-block to contain the underline */
    position: relative;
    /* Position relative for pseudo-element */
}


/* Define the orange underline for the navigation link */
.adminmainnavbarbottom p::before {
    content: '';
    /* Create an empty pseudo-element */
    width: 100%;
    height: 2px;
    /* Adjust the thickness of the underline */
    background: var(--Button-Color, linear-gradient(96deg, rgba(127, 226, 80, 0.77) -10.44%, rgba(11, 255, 196, 0.77) 108.53%, rgba(11, 138, 255, 0.77) 108.54%));
    /* Orange color for the underline */
    position: absolute;
    /* Position absolute within the relative parent */
    bottom: 0;
    /* Position it at the bottom of the link */
    left: 0;
    transform: scaleX(0);
    /* Initial scale to 0 for animation */
    transform-origin: left;
    transition: transform 0.2s ease;
    /* Add animation for the underline */
}

/* Add animation when hovering over the navigation link */
.adminmainnavbarbottom p:hover::before {
    transform: scaleX(1);
    /* Scale up to show the underline */
    transform-origin: left;
}

/* Add animation for exiting the hover state */
.adminmainnavbarbottom p::after {
    content: '';
    /* Create an empty pseudo-element */
    width: 100%;
    height: 2px;
    /* Adjust the thickness of the underline */
    background: var(--Button-Color, linear-gradient(96deg, rgba(127, 226, 80, 0.77) -10.44%, rgba(11, 255, 196, 0.77) 108.53%, rgba(11, 138, 255, 0.77) 108.54%));
    /* Orange color for the underline */
    position: absolute;
    /* Position absolute within the relative parent */
    bottom: 0;
    /* Position it at the bottom of the link */
    left: 0;
    transform: scaleX(0);
    /* Initial scale to 0 for animation */
    transform-origin: left;
    transition: transform 100s ease;
    /* Add animation for the underline */
}

/* Add animation for exiting the hover state */
.adminmainnavbarbottom p:hover::after {
    transform: scaleX(1);
    /* Scale up to show the underline */
    transform-origin: right;
}

.adminuser span{
    font-weight: 400;
    font-size: 17px;
}

.username_logo_main span{
    width: 8vw;
}

/* Mobile */

.mobile-toggle-btn{
    display: none;
    flex-direction: column;
    cursor: pointer;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.09);
}
@media (max-width: 768px){
    .mobile-toggle-btn {
            display: block!important;
        }

.adminnavbarbottom {
    display: none !important;
    height: 100vh!important;
    padding-top: 18px;
    padding-bottom: 12px;
    align-items: center;
    padding-left: 32px;
    gap: 10px !important;
    justify-content: space-between;
    /* width: 100%; */
    padding-right: 32px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.09);
}

.adminnavbarbottom p {
    align-items: center;
    cursor: pointer;
    width: 100% !important;
}

.adminmainnavbarbottom {
    display: none!important;
    height: 100vh !important;
    padding-top: 18px;
    padding-bottom: 12px;
    align-items: center;
    padding-left: 32px;
    gap: 68px !important;
    justify-content: space-between;
    /* width: 100%; */
    padding-right: 32px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.09);
}

.adminmainnavbarbottom p {
    align-items: center;
    cursor: pointer;
    width: 100% !important;
}
}