@media (max-width: 575px) {

            .main-navbar-links {
                flex-direction: column;
                text-align: start;
                display: none;
                gap: 20px !important;
                background-color: #001C30;
                opacity: 0;
                /* Initially hide the mobile menu */
                transform: translateY(-20px);
                /* Initial position above */
            }
    
            .main-navbar-links.open {
                display: flex;
                opacity: 1;
                /* Show with opacity */
                transform: translateY(0);
                /* Move to the normal position */
            }
    
            .main-navbar-links a {
                opacity: 0;
                transform: translateY(-20px);
                transition: opacity 0.5s ease, transform 0.5s ease;
            }
    
            .main-navbar-links.open a {
                opacity: 1;
                transform: translateY(0);
            }
    
            .navlinkse {
                margin-top: 10px;
            }
    
            .main-navbar-button {
                display: none;
            }
    
            .button-main-navbar {
                display: block;
                margin-top: 10px;
            }
    
            .menu-toggle {
                display: block;
                text-align: end;
                color: white;
            }
}

