.mloginform{
    width: 40;
    height: 444px;
    flex-shrink: 0;
    border-radius: 30px;
    background: var(--Background-onecolor, #ECEFF1);
    margin-top: -10%;
    margin-left: 30%;
    margin-right: 30%;
    margin-bottom: 5%;
    padding: 40px;
    text-align: center;
}
.logimgreset{
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    margin-left: 33%;
    margin-right: 33%;
    margin-top: 2%;
}
.mlogmainf{
    text-align: center;
}

.mplog{
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.loginputone{
    display: flex;
    padding: 13px 284px 13px 22px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 15px;
    margin-left: 5%;
    border: 1px solid rgba(0, 0, 0, 0.40);
    background: #FFF;
    
}

.logtop{
    margin-top: 6%;
}

.logtopi {
    margin-top: 3%;
    margin-bottom: 2%;
}

.login_change_password{
    cursor: pointer;
}

/* Mobile */
@media (min-width:0px) and (max-width: 399px) {
    .mloginform {
        width: 76.4%;
        height: 444px;
        margin-left: 1%;
        margin-right: 0%;
    }


    .loginputone-lops {
        padding: 10.45px 90.64px 10.607px 19.266px;
        width: 55% !important;

    }

    .login_change_password p{
        font-size: 13px;
    }
}

@media (min-width:400px) and (max-width: 469px) {
    .mloginform {
        width: 77.6%;
        height: 444px;
        margin-left: 1%;
        margin-right: 0%;
    }


    .loginputone-lops {
        padding: 10.45px 90.64px 10.607px 19.266px;
        width: 55% !important;

    }

        .login_change_password p {
            font-size: 13px;
        }
}

@media (min-width:470px) and (max-width: 575px) {
    .mloginform {
        width: 83%;
        height: 444px;
        margin-left: 1%;
        margin-right: 1%;
    }

        .loginputone-lops {
            padding: 10.45px 90.64px 10.607px 19.266px;
            width: 60% !important;
    
        }

                .login_change_password p {
                    font-size: 14px;
                }
}

