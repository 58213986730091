/* VAData.css */

.client_vadate_dialog-background {
    background-color: rgba(0, 0, 0, 0.12) !important;
    padding: 0px !important;
    margin: 0px !important;
}

.client_va_data_main_content {
    display: flex;
    justify-content: space-between;
    height: 80vh;
    overflow: auto;
    gap: 30px;
    z-index: 14 !important;
}

.client_va_data_main_profile {
    width: 340px;
    /* Adjust the width as needed */
    height:76vh;
    /* Adjust the height as needed */
    /* To make it a circle */
    background: #ECEFF1;
    overflow: hidden;
    border-radius: 30px;
    /* Ensure the image doesn't overflow the circle */
}

.client_va_data_main_document {
    width: 480px;
    height: 76vh;
    margin-bottom: 4vh;
    /* Adjust the height as needed */
    flex-shrink: 0;
    border-radius: 30px;
    background: #ECEFF1;
    overflow: hidden;
}

.client_va_data_main_qualifiction {
    width: 480px;
    height: 76vh;
    /* Adjust the height as needed */
    flex-shrink: 0;
    border-radius: 30px;
    background: #ECEFF1;
    overflow: hidden;
}

.email_va_data_image img {
    height: 80px!important;
    width: 80px!important;
    /* object-fit: contain!important; */
    /* Maintain image aspect ratio and cover the container */
    border-radius: 50%!important;
    padding-top: 50px!important;
    /* Match the circle border-radius of the container */
}

.email_va_contact_data{
    display: flex!important;
    justify-content: space-between!important;
    padding-left: 20px!important;
    padding-right: 20px!important;
}

.email_va_contact_hr{
   padding-left: 20px !important;
   padding-right: 20px !important;
}
.email_va_contact_data_btn{
    padding-top: 30px!important;
}

.email_va_contact_data_btn button{
    font-size: 14px!important;
    margin-bottom: 50px!important;
}

.email_va_data_paragraph_title p{
    color: black!important;
    font-weight: 600!important;
    padding-top: 20px;
}

.email_va_contact_data_paragraph_title p{
    font-weight: 600!important;
    color: black!important;
    padding-top: 20px;
}

.email_va_contact_data_paragraph_body{
    font-weight: 400 !important;
    color: black !important;
    min-height: 50vh;
    overflow: auto;
}
/* ... rest of your CSS ... */

/* Mobile */
@media (min-width: 0px) and (max-width: 480px) {
    .client_va_data_main_profile {
            width: 45%!important;
            /* Adjust the width as needed */
            height: 76vh;
            /* Adjust the height as needed */
            /* To make it a circle */
            background: #ECEFF1;
            overflow: hidden;
            border-radius: 30px;
            /* Ensure the image doesn't overflow the circle */
        }
    
        .client_va_data_main_document {
            width: 53%!important;
            height: 76vh;
            margin-bottom: 4vh;
            /* Adjust the height as needed */
            flex-shrink: 0;
            border-radius: 30px;
            background: #ECEFF1;
            overflow: hidden;
        }
    
        .client_va_data_main_qualifiction {
            width: 53%!important;
            height: 76vh;
            /* Adjust the height as needed */
            flex-shrink: 0;
            border-radius: 30px;
            background: #ECEFF1;
            overflow: hidden;
        }
    
        .email_va_data_image img {
            height: 80px !important;
            width: 80px !important;
            /* object-fit: contain!important; */
            /* Maintain image aspect ratio and cover the container */
            border-radius: 50% !important;
            padding-top: 50px !important;
            /* Match the circle border-radius of the container */
        }
}

/* Small devices (portrait) */
@media (min-width: 481px) and (max-width: 575px) {
.client_va_data_main_profile {
        width: 100% !important;
        /* Adjust the width as needed */
        height: 76vh;
        /* Adjust the height as needed */
        /* To make it a circle */
        background: #ECEFF1;
        overflow: hidden;
        border-radius: 30px;
        /* Ensure the image doesn't overflow the circle */
    }

    .client_va_data_main_document {
        width: 100% !important;
        height: 76vh;
        margin-bottom: 4vh;
        /* Adjust the height as needed */
        flex-shrink: 0;
        border-radius: 30px;
        background: #ECEFF1;
        overflow: hidden;
    }

    .client_va_data_main_qualifiction {
        width: 100% !important;
        height: 76vh;
        /* Adjust the height as needed */
        flex-shrink: 0;
        border-radius: 30px;
        background: #ECEFF1;
        overflow: hidden;
    }

    .email_va_data_image img {
        height: 55px !important;
        width: 55px !important;
        /* object-fit: contain!important; */
        /* Maintain image aspect ratio and cover the container */
        border-radius: 50% !important;
        padding-top: 50px !important;
        /* Match the circle border-radius of the container */
    }
}