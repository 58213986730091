.servicard{
    border-radius: 30px;
    background: #FFF;
    display: flex;
    width: auto;
    height: auto;
    padding: 60px 20px;
    justify-content: space-between;
}
.cardserve{
    width: 336px;
    height: 333px;
    margin-right: 2.5%;
    margin-left: 2.5%;
    flex-shrink: 0;
    text-align: center;
    border-radius: 30px;
    background: rgba(236, 239, 241, 0.60);
}
.cardserve:hover{
    width: 336px;
    height: 333px;
    text-align: center;
    flex-shrink: 0;
    border-radius: 30px;
    background: rgba(196, 224, 242, 0.60);
}
.servp{
    font-weight: 400!important;
    font-size: 18px!important;
}
.servpe {
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 30px;
}
.servc{
    padding-right: 10px;
    height: 25px;
    width: 25px;
}
.spy{
    /* display: flex; */
    display: flex;
    align-items: center!important;
    align-items: center;
    padding-right: 20px;
    padding-left: 60px;
}
.serhr{
    width: 163.003px;
    height: 1px;
    background: linear-gradient(96deg, rgba(127, 226, 80, 0.77) -10.44%, rgba(11, 255, 196, 0.77) 108.53%, rgba(11, 138, 255, 0.77) 108.54%);
}
.servpi{
    font-weight: 800;
}

/* Mobile */
@media (max-width: 575px) {
    .servicard {
            border-radius: 20px;
            background: #FFF;
            display: block;
            width: auto;
            height: auto;
            padding: 20px 20px;
            justify-content: space-between;
        }
    
        .cardserve {
            width: 100%;
            height: 300px;
            margin-right: 0%;
            margin-left: 0%;
            flex-shrink: 0;
            text-align: center;
            border-radius: 20px;
            background: rgba(236, 239, 241, 0.60);
        }
    
        .cardserve:hover {
            width: 100%;
            height: 300px;
            text-align: center;
            flex-shrink: 0;
            border-radius: 20px;
            background: rgba(196, 224, 242, 0.60);
        }
    
        .servp {
            font-weight: 400 !important;
            font-size: 14px !important;
        }
    
        .servpe {
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 30px;
            padding-top: 30px;
        }

        .servp-spy{
            display: none!important;
        }
    
        .servc {
            padding-right: 10px;
            height: 25px;
            width: 25px;
        }
    
        .spy {
            /* display: flex; */
            display: flex;
            align-items: center !important;
            align-items: center;
            padding-right: 20px;
            padding-left: 60px;
        }
    
        .serhr {
            width: 163.003px;
            height: 1px;
            background: linear-gradient(96deg, rgba(127, 226, 80, 0.77) -10.44%, rgba(11, 255, 196, 0.77) 108.53%, rgba(11, 138, 255, 0.77) 108.54%);
        }
    
        .servpi {
            font-weight: 800;
        }
}