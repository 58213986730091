.mlandingservices {
    /* background: #F5F5F5; */
    padding: 30px 32px;
    text-align: center;
    background: white;
}

.mlandingservicesi{
    /* background: #F5F5F5; */
    padding-top: 30px;
    padding-bottom: 0px;
    padding-right: 32px;
    padding-left: 32px;
    text-align: center;
    background: white;
}

.mlandpara {
    font-weight: 800;
    font-size: 30px;
    line-height: 40px;
}

.mlandparei {
    background: var(--Button-Color, linear-gradient(96deg, rgba(127, 226, 80, 0.77) -10.44%, rgba(11, 255, 196, 0.77) 108.53%, rgba(11, 138, 255, 0.77) 108.54%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
}

.mlandparagr {
    font-weight: 400;
    /* line-height: 20px; */
    font-size: 20px;
}

.mlandparagri {
    font-size: 18px;
    font-weight: 300;
}

.mlandservone {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
}

.mlandserbtn {
    padding-top: 30px;
    padding-bottom: 30px;
}

/* Mobile */
@media (max-width: 575px) {
    .landing-services-industries{
        display: none;
    }
}