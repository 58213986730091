/* Card.css */
.ficard {
    /* width: 200px;
    padding: 20px; */
    /* background-color: #f0f0f0; */
    transition: transform 0.3s;
    text-align: center;
    /* border: 1px solid #ccc; */
    width: 212px;
    height: 284px;
    /* display: none; */
    padding: 0px 60px 0px 60px;
    /* justify-content: flex-end; */
    align-items: center;
    padding-top: 10px;
    /* flex-shrink: 0; */
    text-align: center;
    border-radius: 30px;
    background: rgba(236, 239, 241, 0.60);
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.08);
}

.ficard:hover {
    transform: scale(1.1);
    /* Enlargement effect on hover */
    background: rgba(196, 224, 242, 0.60);
    /* Penumber */
    box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    z-index: 1!important;
}

.webTop {
    font-weight: 800;
    font-size: larger;
}

/* Mobile */
@media (min-width: 0px) and (max-width: 400px) {
    .ficard {
        width: 66%;
        margin-bottom: 30px;
    }
}
@media (min-width: 401px) and (max-width: 500px) {
    .ficard{
        width: 69%;
        margin-bottom: 30px;
    }
}

@media (min-width: 501px) and (max-width: 575px) {
    .ficard {
        width: 77%;
        margin-bottom: 30px;
    }
}